import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from "../views/Login"
import Main from "../views/Main"
import Warehouse from "../views/Warehouse"
import Scanner from "../views/Scanner"
import Login from "../views/Login"

import NotFound from "@/components/NotFound.vue";

import axios from "@/api/axios"
import store from "@/store"

Vue.use(VueRouter)
const routes = [
    {
        path: '/main',
        name: 'Main',
        component: Main,
        meta: { layout: "default-layout", requiresAuth: true }
    },
    {
        path: '/warehouse',
        name: 'Warehouse',
        component: Warehouse,
        meta: { layout: "default-layout", requiresAuth: true }
    },
    {
        path: '/scanner',
        name: 'Scanner',
        component: Scanner,
        meta: { layout: "default-layout", requiresAuth: true }
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: { layout: "login-layout" }
    },
    {
        path: "*",
        component: NotFound,
        meta: { layout: "login-layout" }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Vue.$cookies.get('token')) {
            await new Promise((resolve, reject) => {
                axios.defaults.headers.common['Content-Type'] = 'application/json';

                let headers = {
                    withCredentials: true,
                }

                const token = Vue.$cookies.get('token')
                if (token) {
                    headers['x-access-token'] = token
                }

                axios.request({
                    url: '/verify_token',
                    method: 'GET',
                    headers: headers
                })
                    .then(async ({ data }) => {
                        if (data.auth == true) {
                            resolve(true)
                        } else {
                            reject(2)
                        }
                    })
                    .catch((err) => {
                        reject(err)
                    })
            }).then(r => {
                next()
            }).catch(err => {
                Vue.$cookies.remove("token");
                next({ path: '/' })
            })
        } else {
            next({ path: '/' })
        }
    } else {
        next()
    }

})

export default router
