<template>
    <div class="table-scroll">
        <table :class="customClass">
            <thead>
                <slot name="head"></slot>
            </thead>
            <tbody>
                <slot name="body"></slot>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: "TableBlock",
    props: {
        customClass: {
            typeof: String,
            default: ''
        }
    },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.table-scroll {
    overflow-x: scroll;
    height: 100%;

    table {
        border-spacing: 0;
        min-width: 100%;

        th,
        td {
            border-right: 1px solid #a5a5a5;
        }

        thead {
            background: $color-gray;
            padding: 10px 15px;
            border-bottom: 1px solid #e2e2e2;
            font-weight: 700;
            left: 0;

            th {
                background: $color-gray;
                padding: 10px 5px;
                white-space: nowrap;
                text-align: left;
            }
        }

        tbody {
            tr {
                &:hover {
                    td {
                        background: rgba(0, 0, 0, .1) !important;
                    }
                }

                td {
                    padding: 10px 5px;
                    background: #fff;
                    transition: all 0.3s;
                }

                &:not(:last-child) {
                    td {
                        border-bottom: 1px solid #a5a5a5;
                    }
                }

                &:hover {
                    td {
                        background: #f5f6f7;
                    }
                }
            }
        }
    }
}</style>