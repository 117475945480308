<template>
    <div class="tree-checkbox" :class="{ ['tree-level-' + level]: true }">
        <ul>
            <li v-for="item in items" :key="item.ID" :class="{ ['level-' + level]: true }">
                <Grid :column="2" :gap="5" :customStyle="{ alignItems: 'center' }">
                    <v-checkbox color="#629F33" :label="item.Description" v-model="selectInTree" :value="item.ID"
                        @change="change($event, item)"></v-checkbox>
                    <div class="text-right">
                        <button class="ghost" v-if="item.children && item.children.length > 0" @click="showHide(item.ID)">
                            <Icon width="30" height="30" viewBox="0 0 20 20" stroke="#000000" strockeWidth="2"
                                :iconName="'chevron-down'" />
                        </button>
                    </div>
                </Grid>
                <template v-if="item.children && item.children.length > 0 && openChildren == item.ID">
                    <!--" -->
                    <!-- <vue-slide-up-down class="options" :active="openChildren == item.ID"> -->
                    <tree-checkbox :items="item.children" :level="level + 1" :selectedItem="selectedItem"
                        :dropItem="dropItem" :select="selectInTree" />
                    <!-- </vue-slide-up-down> -->
                </template>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "TreeCheckbox",
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        select: {
            type: Array,
            default: () => [],
        },
        level: {
            type: Number,
            default: 0,
        },
        selectedItem: {
            type: Function,
            required: true,
        },
        dropItem: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            openChildren: null
        }
    },
    computed: {
        selectInTree() {
            return this.select
        }
    },
    methods: {
        change(state, item) {
            if (!this.select.includes(item.ID)) {
                this.selectedItem({ id: item.ID, name: item.Description })
            } else {
                this.dropItem(item.ID)
            }
        },
        showHide(id) {
            if (this.openChildren === null) {
                this.openChildren = id
            } else {
                this.openChildren = null
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.tree-checkbox {
    &.tree-level-0 {
        padding-top: 20px;
    }

    ul {
        padding-left: 20px;
        list-style: none;

        li {
            border-left: 1px dashed #000;
            padding-left: 5px;

            &:not(:last-child) {
                border-bottom: 1px solid #000;
                margin-bottom: 10px;
            }

            .v-input {
                margin-top: 0 !important;
            }

            .text-right {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
</style>