<template>
    <div :class="{ block: true, 'with-border': withBorder, 'overflow-hidden': overflowHidden }"
        :style="{ padding: `${padding}px`, ...customStyle }">
        <slot />
    </div>
</template>
<script>
export default {
    name: "Block",
    props: {
        withBorder: {
            typeof: Boolean,
            default: true
        },
        overflowHidden: {
            typeof: Boolean,
            default: false
        },
        padding: {
            typeof: Number,
            default: 0
        },
        customStyle: {
            typeof: Object,
            default: () => { }
        },
    },
}
</script>
<style scoped lang="scss">
.block {
    background: #fff;
    border-radius: 12px;
    width: 100%;
    max-width: 85vw;

    @media screen and (max-width: 576px) {
        max-width: 100%;

    }

    &.overflow-hidden {
        overflow: hidden;
    }

    &.with-border {
        border: 1px solid #E0E0E0;
    }
}
</style>