<template>
    <div class="notifications">
        <div class="notification" v-for="(n, ind) of notifications" :key="n['_id']">
            <div class="notification__top">
                <div class="date">
                    {{ $moment(n.date).format('DD.MM.YYYY HH:mm') }}
                </div>
                <Icon width="20" height="20" :iconName="'x'" stroke="#767676" @click="close(n['_id'], ind)" />
            </div>
            <div class="notification__message" v-html="n.message"></div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: "Notifications",
    computed: {
        ...mapState({
            WSMessage: state => state.WSMessage,
            user: state => state.user,
            storeNotifications: state => state.notifications,
        }),
    },
    mixins: [globalMethods],
    props: {},
    watch: {
        storeNotifications: function (val) {
            if (val) {
                this.$set(this, 'notifications', val)
            }
        },
        WSMessage: function (val) {
            if (typeof val === "object" && val !== null) {
                if (val?.action == 'newNotification') {
                    this.loadNotification(false)
                } else if (val?.action == 'processedJoinExcel') {
                    this.$set(this, 'notifications', [{
                        message: val.data
                    }])
                }
            }
        },
        user: function (val) {
            if (val) {
                this.loadNotification()
            }
        },

    },
    data() {
        return {
            notifications: []
        }
    },
    methods: {
        loadNotification(preloader = true) {
            this.sendRequest('POST', { role: this.user?.role }, '/get_notifications', {}, preloader, true)
                .then(response => {
                    this.$set(this, 'notifications', response)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        close(id, ind) {
            if (id) {
                this.sendRequest('PUT', { id }, '/close_notification', {}, true, true)
                    .then(response => {
                        this.$delete(this.notifications, ind)
                    })
                    .catch(err => {
                        this.$store.commit('setError', err)
                    })
            } else {
                this.$delete(this.notifications, ind)
            }
        }
    }
}
</script>
<style scoped lang="scss">
.notifications {
    position: absolute;
    top: 5vw;
    right: 10px;
    width: 300px;
    z-index: 9;

    .notification {
        background: #fff !important;
        font-style: normal !important;
        font-size: 16px !important;
        line-height: 20px !important;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        padding: 20px;
        animation: shakeJump 1s both;
        animation-delay: .1s;
        position: relative;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        @keyframes shakeJump {
            0% {
                right: -100%;
            }

            55% {
                right: 25px;
            }

            70% {
                right: 0px;
            }

            80% {
                right: 17px;
            }

            90% {
                right: 7px;
            }

            100% {
                right: 10px;
            }
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;

            .date {
                font-weight: bold;
                font-size: 10pt;
            }

            svg {
                cursor: pointer;
            }
        }
    }
}
</style>