import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        notifications: null,preloader: false, error: null, user: null, socket: null, WSMessage: null, windowWidth: window.innerWidth, newNotification: false,
        pageName: {
            Drop: 'Drop',
            Arithmetic: 'Арифметика',
        }
    },
    mutations: {
        showPreloader: (state, data) => state.preloader = data,
        setError: (state, data) => state.error = data,
        clearError: (state) => state.error = null,
        setUser: (state, data) => state.user = data,
        setSocket: (state, data) => state.socket = data,
        setWSMessage: (state, data) => state.WSMessage = data,
        setWindowWidth: (state, data) => state.windowWidth = data,
        setNewNotification: (state, data) => state.newNotification = data,
        setNotification: (state, data) => state.notifications = [{ message: data }],
        // setUserInterface: (state, data) => state.userInterface = data,
        // unSetUserInterface: (state) => state.userInterface = null,
        // setUserType: (state, data) => state.userType = data,
        // setUserFIO: (state, data) => state.userFIO = data,
        // showPreloader: (state, data) => state.preloader = data,
        // setError: (state, data) => state.error = data,
        // clearError: (state) => state.error = null,
    },
    actions: {},
    modules: {}
})