import Vue from 'vue'
import App from './App.vue'
import DefaultLayout from "./layouts/default"
import LoginLayout from "./layouts/login"
import moment from 'moment';
import router from './router'
import store from './store'
import DesignElements from './plugins/import-design-elements.js'
import vuetify from './plugins/vuetify'
import VueCookies from './plugins/vue-cookies'
// import VueSlideUpDown from './plugins/vue-slide-up-down'
// import Datepicker from './plugins/vue2-datepicker'
// import VueQrcodeReader from './plugins/vue-qrcode-reader.js'
// import VueQrcodeReader from './plugins/vue-qrcode-reader.js'

import 'normalize.css/normalize.css'
import './style.scss'

Vue.config.productionTip = false

Vue.prototype.$moment = moment;

Vue.component("default-layout", DefaultLayout)
Vue.component("login-layout", LoginLayout)

Vue.prototype.$config = {
  apiUrl: 'https://vovan.ag-platform.ru/api/',
  imageUrl: 'https://vovan.ag-platform.ru/public/',
  // Другие настройки
};

new Vue({
  router,
  store,
  vuetify,
  DesignElements,
  VueCookies,
  // VueQrcodeReader,
  // VueSlideUpDown,
  // Datepicker,
  // VueQrcodeReader,
  render: h => h(App)
}).$mount('#app')
