<template>
  <transition name="fade">
    <div class="modal-backdrop" v-if="open" @click.self="close">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription"
        :class="{ [`padding-${padding}`]: true, [size]: true }">
        <Icon width="20" class="close-icon" height="20" :iconName="'x-circle'" stroke="#767676" @click="close" />
        <header class="modal-header" id="modalTitle" :class="!captinType ? 'no-border' : ''" v-if="!hideHeader">
          <slot name="header"></slot>
        </header>
        <section class="modal-body" id="modalDescription">
          <slot name="body"></slot>
        </section>
        <footer class="modal-footer" :class="{ 'not-border': notFooterBorder }">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
import Icon from '@/components/Icons.vue';
export default {
  name: "modal",
  components: { Icon },
  props: {
    notFooterBorder: {
      typeof: Boolean,
      default: false
    },
    hideHeader: {
      typeof: Boolean,
      default: false
    },
    size: {
      typeof: String,
      default: ''
    },
    padding: {
      typeof: String,
      default: '30-90'
    }
  },
  data() {
    return {
      open: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      captinType: false,
      closeAsCancel: false
    };
  },
  methods: {
    show(opts = {}) {
      if (typeof opts.preload === 'function') {
        opts.preload()
      }
      if (opts.confirmNow === true) {
        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve;
          this.rejectPromise = reject;
          this.close();
          this.resolvePromise(true);
        });
      }
      this.open = true;
      this.captinType = opts.captin_type || false;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.close();
      this.resolvePromise(false);
    },
    close() {
      this.open = false;
      if (this.closeAsCancel) {
        this.resolvePromise(false);
      }
    },
  },
};
</script>
<style lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);



  .modal {
    background: #ffffff;
    // box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-width: 700px;
    width: 700px;
    color: #1c1c1c;
    max-height: 99%;

    border-radius: 12px;
    position: relative;

    &.padding-30-90 {
      padding: 30px 90px;

      .modal-body {
        padding: 20px 10px;
      }
    }

    &.padding-20 {
      padding: 20px;
    }

    &.padding-70-120 {
      padding: 70px 120px;
    }
    &.padding-57-94 {
      padding: 94px 57px;
    }

    &.small{
      max-width: 393px;
    }
    &.medium{
      max-width: 560px;
    }
    @media screen and (max-width: 560px) {
      max-width: 95%;
      width: 95%;
    }

    .close-icon {
      position: absolute;
      right: 27px;
      top: 27px;
      cursor: pointer;
      transition: all 0.3s;
      color: #000;
      z-index: 5;

      &:hover {
        color: var(--dl-color-danger-300);
      }
    }

    &.full {
      max-width: 99%;
      max-width: 99%;
      width: 100%;
      height: 100%;
    }

    .modal-header,
    .modal-footer {
      padding: 5px 10px;
      display: flex;      

      button {
        margin-top: 0;
        border-radius: 5px;
      }
    }

    .modal-header {
      border-bottom: 1px solid #eeeeee;
      color: #1A1A1A;
      font-family: 'Circe';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      justify-content: center;
      position: relative;
      min-height: 25px;

      &.no-border {
        border-bottom: none;
      }
    }

    .modal-footer {
      border-top: 1px solid #eeeeee;      
      justify-content: flex-end;
      gap: 20px;

      &.not-border {
        border: none;
      }

      .btn {
        &:not(:last-child) {
          margin-right: 10px;
        }

        &.reject {
          background: rgba(0, 0, 0, 0.5) !important;
          color: #fff;
        }
      }
    }

    .modal-body {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .btn-close {
      border: none;
      font-size: 20px;
      padding: 20px;
      cursor: pointer;
      font-weight: bold;
      color: #4aae9b;
      background: transparent;
    }

    .btn-green {
      color: white;
      background: #4aae9b;
      border: 1px solid #4aae9b;
      border-radius: 2px;
    }
  }
}
</style>