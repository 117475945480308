<template>
  <div class="default-template">
    <Grid :column="isMobyle ? 1 : 2" :gap="0" :style="gridStyle">
      <div class="menu-block">
        <template v-for="(m, ind) of  menu ">
          <router-link :to="{ path: m.url }" :key="'menu-' + ind" exact exact-active-class="active">
            <div class="icon-circle"></div>
            <span>{{ m.name }}</span>
          </router-link>
        </template>
      </div>
      <div class="top-block d-flex align-items-center justify-content-space-between" v-if="false">
        <v-btn variant="outlined" class="btn-w-border go-back" @click="$router.go(-1)">
          <Icon width="24" height="24" :iconName="'arrow-left'" stroke="#000" />
        </v-btn>
        <div class="user">

        </div>
      </div>
      <div class="content-block">
        <slot />
      </div>
    </Grid>
    <!-- <Header /> -->
    <!-- <Sidebar /> -->

    <Notifications />
    <Preloader />
    <Error />
  </div>
</template>

<script>
// import Header from "../components/Header";
// import Sidebar from "../components/Sidebar";
import Preloader from "../components/Preloader";
import Error from "../components/Errors";
import { mapState } from 'vuex'
import { globalMethods } from '@/mixins/globalMethods'
import { RouterLink } from "vue-router";
import Notifications from '@/components/Notification'

export default {
  name: "DefaultLay",
  components: { Preloader, Error, RouterLink, Notifications },
  mixins: [globalMethods],

  computed: {
    ...mapState({
      user: state => state.user,
      windowWidth: state => state.windowWidth,
      WSMessage: state => state.WSMessage,
    }),
    isMobyle() {
      return this.windowWidth <= 560
    },
    gridStyle() {
      if (this.windowWidth > 560) {
        return { gridTemplateColumns: '135px 1fr', gridTemplateRows: '78px 1fr', height: '100vh' }
      } else {
        return {
          gridTemplateColumns: 'unset',
          gridTemplateRows: '70px auto',
          height: 'unset',
          minHeight: '100vh'
        }
      }
    },
    role: function () {
      let role
      if (this.user?.role == 1) {
        role = 'Администратор'
      } else if (this.user?.role == 2) {
        role = 'Начальник производства'
      } else if (this.user?.role == 3) {
        role = 'Складовщик'
      }
      return role
    },
    // menu: function () {
    //   let menu = []
    //   if (this.user?.role == 1) {
    //     menu.push({
    //       url: '/manegers',
    //       name: 'Менеджеры',
    //       icon: 'users',
    //     })
    //     menu.push({
    //       url: '/DAMBUL-SHOP',
    //       name: 'DAMBUL-SHOP',
    //       icon: null,
    //       img: '3.jpeg'
    //     })
    //     menu.push({
    //       url: '/goods',
    //       name: 'DAMBUL—KIDS',
    //       icon: null,
    //       img: '2.jpeg'
    //     })
    //     menu.push({
    //       url: '/drop',
    //       name: 'DAMBUL-DROP',
    //       icon: null,
    //       img: '1.jpeg'
    //     })
    //     menu.push({
    //       url: '/OPT',
    //       name: 'DAMBUL-OPT',
    //       icon: null,
    //       img: '4.jpeg'
    //     })
    //     menu.push({
    //       url: '/stocks',
    //       name: 'Акции',
    //       icon: 'stock'
    //     })
    //     menu.push({
    //       url: '/logistics',
    //       name: 'Логистика',
    //       icon: 'airplane'
    //     })
    //     menu.push({
    //       url: '/commissions',
    //       name: 'Комиссии',
    //       icon: 'server'
    //     })
    //     menu.push({
    //       url: '/join_excel',
    //       name: 'Excel',
    //       icon: null,
    //       img: '5.jpeg'
    //     })
    //     menu.push({
    //       url: '/scanner',
    //       name: 'Сканер',
    //       icon: null,
    //       img: '6.jpeg'
    //     })
    //   } else if (this.user?.role == 2) {
    //     menu.push({
    //       url: '/list',
    //       name: 'Задачи',
    //       icon: 'list'
    //     })
    //     menu.push({
    //       url: '/calculation',
    //       name: 'Расчет',
    //       icon: 'rubl'
    //     })
    //   } else if (this.user?.role == 3) {
    //     menu.push({
    //       url: '/list',
    //       name: 'Задачи',
    //       icon: 'list'
    //     })
    //   }
    //   return menu
    // }
  },
  watch: {
    WSMessage: function (val) {
      if (typeof val === "object" && val !== null) {
        if (val?.action == 'reloadProfile') {
          if (this.user['_id'] == val?.data?.user) {
            this.sendRequest('GET', {}, '/reload_profile', {}, false, true)
              .then(() => {
                // this.$store.commit('setUser', data)
              })
              .catch(err => {
                this.$store.commit('setError', err)
              })
          }
        }
      }
    },
    showUserMenu: function (val) {
      const clickOutside = (e) => {
        if (this.handleClickOutside(e, 'userMenu')) {
          this.$set(this, 'showUserMenu', false)
        }
      }
      if (val) {
        document.addEventListener('click', clickOutside);
      } else {
        document.removeEventListener('click', clickOutside);
      }
      return () => {
        document.removeEventListener('click', clickOutside);
      };
    }
  },
  data() {
    return {
      showUserMenu: false,
    }
  },
  methods: {
    logout() {
      this.$cookies.remove("token");
      location.href = "/"
    },
    goToSettings() {
      this.$router.push('/settings')
    }
  }

}
</script>
<style lang="scss">
@import "@/assets/scss/global-styles.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  color: $color-orange;
}

* {
  outline: none;
}

body,
html {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
  position: relative;
  font-size: 15px;
  color: $color-black;
}

.default-template {
  .menu-block {
    grid-row: 1 / 3;
    padding-top: 100px;

    @media screen and (max-width: 560px) {
      padding-top: 0px;
      grid-row: 1;
      display: flex;
      overflow-y: auto;
      height: 100px;
    }

    a {
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      text-decoration: none;
      transition: all .3s;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #767676 !important;
      border-right: 4px solid #fff;

      @media screen and (max-width: 560px) {
        flex: 1;
        border-right: none;
        border-bottom: 4px solid #fff;
      }

      &.active {
        border-color: #629F33 !important;
        color: #629F33 !important;
        background: #F1F1F1;

        .icon-circle {
          background: #629F33;

        }
      }

      &:hover {
        color: #1A1A1A !important;
        background: #F1F1F1;
        border-color: #F1F1F1;

        .icon-circle {
          background: #1A1A1A;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;

        @media screen and (max-width: 560px) {
          margin-bottom: 0px;
        }
      }

      .icon-circle {
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        height: 32px;
        min-height: 32px;
        max-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        background: #767676;
        border-radius: 50%;
        color: #fff;
        margin-bottom: 8px;

        img {
          max-width: 100%;
          width: 100%;
          height: 100%;

        }
      }
    }

  }

  .top-block {
    width: 100%;
    padding: 0px 3vw;
    position: relative;

    @media screen and (max-width: 560px) {
      grid-row: 1;
    }

    .user {
      position: relative;
      display: flex;

      &__name {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;

        div {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #1A1A1A;
        }

        span {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;

          text-align: right;

          color: #767676;

        }
      }

      &__icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F1F1F1;
        margin-left: 11px;
        border-radius: 50%;
      }
    }

    .user-menu {
      position: absolute;
      right: 0vw;
      top: 50px;
      background: #FFFFFF;
      z-index: 2;
      border: 1px solid #E0E0E0;

      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
      border-radius: 12px;

      button {
        background: #fff !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 13px !important;
        line-height: 16px !important;
        color: #1A1A1A !important;
      }
    }
  }

  .content-block {
    padding: 15px 3vw;
    background: #F1F1F1;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    margin-bottom: 0;
  }

}
</style>
