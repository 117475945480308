<template>
    <div id="price-page">
        <Grid :column="1" :gap="20">
            <Block padding="5">
                <div class="grid-row">
                    <form @submit.prevent="generate()">
                        <div>
                            <span>Артикул:</span>
                            <v-text-field v-model.number="article" autocomplete="off" outlined class="input"></v-text-field>
                        </div>
                        <v-btn type="submit" class="button mt-6" variant="tonal" color="primary"
                            :disabled="article.length < 3">
                            Сгенерировать
                        </v-btn>
                    </form>
                </div>
            </Block>
            <Block padding="5">
                <div class="right">
                    <v-pagination :total-visible="5" v-model="currentPage" :length="totalPages"></v-pagination>
                </div>
            </Block>
            <Block>
                <TableBlock>
                    <template v-slot:head>
                        <tr>
                            <th>Артикул</th>
                            <th>QR</th>
                            <th></th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr v-for="g of goodsList" :key="'g-' + g['_id']">
                            <td>
                                <h3>{{ g.article }}</h3>
                            </td>
                            <td><img :src="$config.imageUrl + g.file"></td>
                            <td>
                                <div class="btns">
                                    <button class="ghost printer" @click="printQR(g.file)">
                                        <img src="@/assets/printer.svg">
                                    </button>
                                    <button class="ghost download" @click="downloadQR(g.file)">
                                        <img src="@/assets/download.svg">
                                    </button>
                                    <button class="ghost delete" @click="deleteQR(g['_id'], g.file)">
                                        <img src="@/assets/trash-2.svg">
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </template>
                </TableBlock>
            </Block>
            <Block padding="5">
                <div class="right">
                    <v-pagination :total-visible="5" v-model="currentPage" :length="totalPages"></v-pagination>
                </div>
            </Block>
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
// import InfiniteScroll from 'vue-infinite-scroll';

export default {
    name: 'PricePage',
    // components: {
    // },
    mixins: [globalMethods],
    computed: {
        countItemsHtml() {
            let row = []
            return Object.entries(this.countItems).map(r => r.join(': ')).join('; ')
        }
    },
    created() {

    },
    data() {
        return {
            article: '',
            goodsList: [],
            goodsTotalList: [],
            page: 0,
            items: [],
            goodsClearList: [],
            totalPages: 0,
            currentPage: 1
        }
    },
    mounted() {
        this.filter()
    },
    watch: {
        currentPage: function (val) {
            const nextPage = val - 1
            this.goodsList = this.goodsTotalList[nextPage]
        },
    },
    methods: {
        generate() {
            this.sendRequest('POST', { article: this.article }, '/generate_qr', {}, true, true)
                .then(response => {
                    let newList = [...this.goodsClearList, {
                        article: this.article,
                        file: response.file,
                        '_id': response.id,
                    }]
                    let chunk = this.arrayChunk(newList, 20)
                    this.totalPages = chunk.length
                    this.$set(this, 'goodsClearList', newList)
                    this.$set(this, 'goodsTotalList', chunk)
                    this.$set(this, 'goodsList', chunk[0])
                    this.$set(this, 'article', '')
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        filter() {
            let filter = {}
            this.sendRequest('GET', filter, '/get_qr', {}, true, true)
                .then(response => {
                    // response                    
                    let chunk = this.arrayChunk(response, 20)
                    this.totalPages = chunk.length
                    this.$set(this, 'goodsClearList', response)
                    this.$set(this, 'goodsTotalList', chunk)
                    this.$set(this, 'goodsList', chunk[0])

                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },

        changePage(action) {
            // {{currentPage}} из {{ goodsTotalList.length }}
            if (action == 'next') {
                let nextPage = this.page + 1
                if (nextPage <= this.goodsTotalList.length) {
                    this.$set(this, 'page', nextPage)
                } else {
                    this.$set(this, 'page', this.goodsTotalList.length)
                }
            } else {
                let prevPage = this.page - 1
                if (prevPage > 0) {
                    this.$set(this, 'page', prevPage)
                } else {
                    this.$set(this, 'page', 0)
                }
            }
            this.$set(this, 'goodsList', this.goodsTotalList[this.page])
        },
        arrayChunk(array, size) {
            const chunks = [];
            let index = 0;
            while (index < array.length) {
                chunks.push(array.slice(index, index + size));
                index += size;
            }
            return chunks;
        },
        loadMoreItems() {
            if (!this.isLoading) {
                this.isLoading = true;

                // Симуляция загрузки данных (замените эту часть на фактический код загрузки данных)
                setTimeout(() => {
                    this.$set(this, 'goodsList', [...this.goodsList, ...this.items.splice(this.gIndex, 100)])
                    this.$set(this, 'gIndex', this.gIndex + 100)
                    this.isLoading = false;
                }, 1000);
            }
        },
        printQR(img) {
            const printWindow = window.open('', '_blank');
            printWindow.document.write('<html><head><title>Print QR</title></head><body>');
            printWindow.document.write('<img src="' + this.$config.imageUrl + img + '">');
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        },
        downloadQR(img) {
            fetch(this.$config.imageUrl + img)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', img);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        },
        deleteQR(id, img) {
            this.sendRequest('DELETE', { id: id, img: img }, '/delete_qr', {}, true, true)
                .then(() => {
                    this.filter()
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
    },
}

</script>
<style lang="scss">
#price-page {
    table {
        height: 100%;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;
        }
    }

    .table-contoll {
        overflow: unset;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 5px;
        height: 100% !important;
    }

    .pag {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .grid-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        >div {
            // flex: 1;
        }

        form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
            align-items: flex-end;

            // @media screen and (max-width: 576px) {
            //     grid-template-columns: 100%;
            // }
        }
    }

    .import-dialog {
        padding-right: 40px;
    }

    .newline {
        td {
            background: #beffc8 !important;
        }
    }

    .button.delete {
        background: #a22020;
        color: #fff;
    }

    .btns {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
</style>