<template>
    <div id="main-page">
        
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
export default {
    name: 'MainPage',
    // components: {
    // },
    mixins: [globalMethods],
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            //   isSubmitting: state => state.auth.isSubmitting,
            //   validationErrors: state => state.auth.validationErrors
        }),        
    },
    watch: {

    },
    methods: {
    }
}
</script>
<style lang="scss"></style>