<template>
    <div class="heading" :class="`heading-color-${color} ${backButton ? 'h-flex' : ''}`">
        <h1 v-if="level == 1" v-html="text" />
        <h2 v-else-if="level == 2" v-html="text" />
        <h3 v-else-if="level == 3" v-html="text" />
        <h4 v-else-if="level == 4" v-html="text" />
        <template v-if="backButton">
            <v-btn plain large class="btn-cancel" @click="$router.go(-1)" color="#d30909">
                Назад
            </v-btn>
        </template>
    </div>
</template>
<script>
export default {
    name: "HeadingComponent",
    props: {
        level: {
            typeof: Number,
            default: 1
        },
        text: {
            typeof: String,
            default: ""
        },
        color: {
            typeof: String,
            default: "black"
        },
        backButton: {
            typeof: Boolean,
            default: false
        },
    },
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/global-styles.scss";

.heading {
    display: block;
    width: 100%;
    position: relative;

    &.h-flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    // &::before {
    //     content: "";
    //     position: absolute;
    //     bottom: 0px;
    //     height: 2px;
    //     width: 25%;
    //     background: $color-orange;
    // }

    &.heading-color-white {
        color: $color-white;
    }

    &.heading-color-black {
        color: $color-black;
    }

    h1,
    h2,
    h3,
    h4 {
        position: relative;
        padding: 0;
        width: auto;
        margin: 0;
    }

    @media screen and (max-width: 576px) {
        h2 {
            font-size: 20pt;
        }
    }

    @media screen and (max-width: 320px) {
        h2 {
            font-size: 17pt;
        }
    }
}
</style>