import axios from '@/api/axios'

const Helpers = {
}

export const globalMethods = {
    data() {
        return {
            menu: [
                {
                    url: '/warehouse',
                    name: 'Склад',
                    icon: 'users',
                },
                {
                    url: '/scanner',
                    name: 'Сканер',
                    icon: null,
                    img: '3.jpeg'
                },

            ],
            profitList: {
                "1": 100,
                "101": 200,
                "201": 400,
                "401": 600,
                "601": 900,
                "901": 1300,
                "1301": 1700,
                "1701": 2500,
                "2501": 3500,
                "3501": 5000,
                "5001": 7500,
                "7501": 10000,
                "10001": 15000,
                "15001": 20000,
                "20001": 25000,
                "25001": 30000,
                "30001": 35000,
                "35001": 40000,
                "40001": 45000,
                "45001": 50000,
                "50001": 70000,
                "70001": 90000,
                "90001": 110000,
                "110001": 150000
            },
        }
    },
    methods: {
        wsParseAnswer(message) {
            try {
                return JSON.parse(message)
            } catch (err) {
                console.error('SOCKET_ERROR => ', err)
                return false
            }

        },
        handleClickOutside(e, ref) {
            const block = this.$refs[ref]
            if (block && block.contains(e.target)) {
                return false;
            }
            return true
        },
        checkName(data) {
            return new Promise((resolve, reject) => {
                if (!data?.name) {
                    return reject('Отсутсвует имя')
                } else if (!Helpers.nameCheck(data.name)) {
                    return reject('Некорректное имя')
                } else {
                    return resolve(data)
                }
            })
        },
        checkEmail(data) {
            return new Promise((resolve, reject) => {
                if (!data?.email) {
                    return reject('Отсутсвует email')
                } else if (!Helpers.emailCheck(data.email)) {
                    return reject('Некорректное email')
                } else {
                    return resolve(data)
                }
            })
        },
        checkId(data) {
            return new Promise((resolve, reject) => {
                if (!data?.id) {
                    return reject('Отсутсвует id')
                } else if (!Helpers.idCheck(data.id)) {
                    return reject('Некорректное id')
                } else {
                    return resolve(data)
                }
            })
        },
        checkStatus(data) {
            return new Promise((resolve, reject) => {
                if (!data?.status) {
                    return reject('Отсутсвует статус')
                } else if (!Helpers.statusCheck(data.status)) {
                    return reject('Некорректный статус')
                } else {
                    return resolve(data)
                }
            })
        },
        checkPhone(data) {
            return new Promise((resolve, reject) => {
                if (!data?.phone) {
                    return reject('Отсутсвует телефон')
                } else if (!Helpers.phoneCheck(data.phone)) {
                    return reject('Некорректный телефон')
                } else {
                    return resolve(data)
                }
            })
        },
        valueModel(value, obj, field) {
            this.$set(obj, field, value)
        },
        checkAccess(access) {
            // if (this.$store.state.user?.owner == 1) {
            //     return true
            // } else {
            // return this.$store.state.user?.settings?.includes(access) || false
            try {
                return this.$store.state.user?.settings?.find(s => s.toLowerCase() === access.toLowerCase()) || false
            } catch (error) {
                return false
            }
            // }
        },
        showModal(modalName) {
            return this.$refs[modalName].show({})
        },
        sendRequest(method, data, url, currentHeaders = {}, preloader, plug) {
            const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';
            // axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:3301' : 'http://monitor.dambul-shop.ru'
            if (!currentHeaders || !currentHeaders?.['Content-Type'] || !currentHeaders?.['content-type']) {
                axios.defaults.headers.common['Content-Type'] = 'application/json';
            }
            let headers = {
                withCredentials: true,
                ...currentHeaders,
            }

            const instance = axios.create({
                timeout: 3600000, // Установите тайм-аут в 1 час (3600000 миллисекунд)
                headers,
            });

            const token = this.$cookies.get('token')
            if (token) {
                headers['x-access-token'] = token
            }

            if (preloader) {
                this.$store.commit('showPreloader', true)
            }
            const errorMessage = (err) => {
                if (err?.response?.data) {
                    if (err?.response?.headers['content-type'] && err.response.headers['content-type'].indexOf('text/html') > -1) {
                        return err.message
                    } else {
                        if (err?.response?.data?.message) {
                            return err.response.data.message;
                        } else {
                            return err.response.data;
                        }
                    }
                } else {
                    return err.message;
                }
            }
            return new Promise((resolve, reject) => {
                axios.request({
                    url,
                    method,
                    [dataOrParams]: data,
                    headers: headers,
                    timeout: 3600000,
                })
                    .then(({ data }) => {
                        resolve(data)
                    })
                    .catch((err) => {
                        reject(errorMessage(err))
                    })
                    .finally(() => {
                        this.$store.commit('showPreloader', false)
                    });
            })
        },
        number_format(number, decimals, dec_point, thousands_sep) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + (Math.round(n * k) / k)
                        .toFixed(prec);
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
                .split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '')
                .length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1)
                    .join('0');
            }
            return s.join(dec);
        }
    }
}