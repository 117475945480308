<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="viewBox" :aria-labelledby="iconName"
        role="presentation" :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round"
        :fill="fill" enable-background="new 0 0 551.13 551.13" @click="click()">


        <path v-if="iconName == 'check'" d="M13.3337 4L6.00033 11.3333L2.66699 8" :stroke="stroke" stroke-linecap="round"
            stroke-linejoin="round" />
        <path v-else-if="iconName == 'x'" d="M12 4L4 12M4 4L12 12" :stroke="stroke" stroke-linecap="round"
            stroke-linejoin="round" />
        <path v-else-if="iconName == 'chevron-down'" d="M4 6L8 10L12 6" :stroke="stroke" stroke-linecap="round"
            stroke-linejoin="round" />
        <path v-else-if="iconName == 'attach'"
            d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48">
        </path>
        <polyline points="15 18 9 12 15 6" v-else-if="iconName == 'chevron-left'"></polyline>
        <polyline points="9 18 15 12 9 6" v-else-if="iconName == 'chevron-right'"></polyline>
        <path v-else-if="iconName == 'plus'" d="M7.99967 3.33325V12.6666M3.33301 7.99992H12.6663" :stroke="stroke"
            stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'check-square'"
            d="M6 7.33333L8 9.33333L14.6667 2.66667M14 8V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H10.6667"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'file-text'"
            d="M9.33366 1.33325H4.00033C3.6467 1.33325 3.30756 1.47373 3.05752 1.72378C2.80747 1.97382 2.66699 2.31296 2.66699 2.66659V13.3333C2.66699 13.6869 2.80747 14.026 3.05752 14.2761C3.30756 14.5261 3.6467 14.6666 4.00033 14.6666H12.0003C12.3539 14.6666 12.6931 14.5261 12.9431 14.2761C13.1932 14.026 13.3337 13.6869 13.3337 13.3333V5.33325M9.33366 1.33325L13.3337 5.33325M9.33366 1.33325L9.33366 5.33325H13.3337M10.667 8.66659H5.33366M10.667 11.3333H5.33366M6.66699 5.99992H5.33366"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'x-circle'"
            d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'phone'"
            d="M15 2V8M15 8H21M15 8L22 1M21.0004 16.92V19.92C21.0016 20.1985 20.9445 20.4741 20.8329 20.7293C20.7214 20.9845 20.5577 21.2136 20.3525 21.4018C20.1473 21.5901 19.905 21.7335 19.6412 21.8227C19.3773 21.9119 19.0978 21.945 18.8204 21.92C15.7433 21.5856 12.7874 20.5341 10.1904 18.85C7.77425 17.3146 5.72576 15.2661 4.19042 12.85C2.5004 10.2412 1.44866 7.27097 1.12042 4.17997C1.09543 3.90344 1.1283 3.62474 1.21692 3.3616C1.30555 3.09846 1.44799 2.85666 1.63519 2.6516C1.82238 2.44653 2.05023 2.28268 2.30421 2.1705C2.5582 2.05831 2.83276 2.00024 3.11042 1.99997H6.11042C6.59573 1.9952 7.06621 2.16705 7.43418 2.48351C7.80215 2.79996 8.0425 3.23942 8.11042 3.71997C8.23704 4.68004 8.47187 5.6227 8.81042 6.52997C8.94497 6.8879 8.97408 7.27689 8.89433 7.65086C8.81457 8.02482 8.62928 8.36809 8.36042 8.63998L7.09042 9.90998C8.51398 12.4135 10.5869 14.4864 13.0904 15.91L14.3604 14.64C14.6323 14.3711 14.9756 14.1858 15.3495 14.1061C15.7235 14.0263 16.1125 14.0554 16.4704 14.19C17.3777 14.5285 18.3204 14.7634 19.2804 14.89C19.7662 14.9585 20.2098 15.2032 20.527 15.5775C20.8441 15.9518 21.0126 16.4296 21.0004 16.92Z"
            :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'email'"
            d="M21 3C21 1.9 20.1 1 19 1H3C1.9 1 1 1.9 1 3M21 3V15C21 16.1 20.1 17 19 17H3C1.9 17 1 16.1 1 15V3M21 3L11 10L1 3"
            :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'save'"
            d="M10.3333 13V7.66667H3.66667V13M3.66667 1V4.33333H9M11.6667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H9.66667L13 4.33333V11.6667C13 12.0203 12.8595 12.3594 12.6095 12.6095C12.3594 12.8595 12.0203 13 11.6667 13Z"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'printer'"
            d="M3.99967 5.99992V1.33325H11.9997V5.99992M3.99967 11.9999H2.66634C2.31272 11.9999 1.97358 11.8594 1.72353 11.6094C1.47348 11.3593 1.33301 11.0202 1.33301 10.6666V7.33325C1.33301 6.97963 1.47348 6.64049 1.72353 6.39044C1.97358 6.14039 2.31272 5.99992 2.66634 5.99992H13.333C13.6866 5.99992 14.0258 6.14039 14.2758 6.39044C14.5259 6.64049 14.6663 6.97963 14.6663 7.33325V10.6666C14.6663 11.0202 14.5259 11.3593 14.2758 11.6094C14.0258 11.8594 13.6866 11.9999 13.333 11.9999H11.9997M3.99967 9.33325H11.9997V14.6666H3.99967V9.33325Z"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'sort'"
            d="M13.3287 3.82843L10.5003 1M10.5003 1L7.67188 3.82843M10.5003 1V11.0001M6.82873 8.17172L4.0003 11.0002M4.0003 11.0002L1.17188 8.17172M4.0003 11.0002L4.0003 1.00007"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'trash'"
            d="M1 3.99992H2.33333M2.33333 3.99992H13M2.33333 3.99992L2.33333 13.3333C2.33333 13.6869 2.47381 14.026 2.72386 14.2761C2.97391 14.5261 3.31304 14.6666 3.66667 14.6666H10.3333C10.687 14.6666 11.0261 14.5261 11.2761 14.2761C11.5262 14.026 11.6667 13.6869 11.6667 13.3333V3.99992L2.33333 3.99992ZM4.33333 3.99992V2.66659C4.33333 2.31296 4.47381 1.97382 4.72386 1.72378C4.97391 1.47373 5.31304 1.33325 5.66667 1.33325H8.33333C8.68696 1.33325 9.02609 1.47373 9.27614 1.72378C9.52619 1.97382 9.66667 2.31296 9.66667 2.66659V3.99992"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
        <path v-else-if="iconName == 'filter'" d="M14.6666 2H1.33325L6.66659 8.30667V12.6667L9.33325 14V8.30667L14.6666 2Z"
            :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round" />
        <template v-else-if="iconName == 'eye'">
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
        </template>
        <template v-else-if="iconName == 'airplane'">
            <path
                d="M407.72 224c-3.4 0-14.79.1-18 .3l-64.9 1.7a1.83 1.83 0 01-1.69-.9L193.55 67.56a9 9 0 00-6.66-3.56H160l73 161a2.35 2.35 0 01-2.26 3.35l-121.69 1.8a8.06 8.06 0 01-6.6-3.1l-37-45c-3-3.9-8.62-6-13.51-6H33.08c-1.29 0-1.1 1.21-.75 2.43l19.84 71.42a16.3 16.3 0 010 11.9L32.31 333c-.59 1.95-.52 3 1.77 3H52c8.14 0 9.25-1.06 13.41-6.3l37.7-45.7a8.19 8.19 0 016.6-3.1l120.68 2.7a2.7 2.7 0 012.43 3.74L160 448h26.64a9 9 0 006.65-3.55L323.14 287c.39-.6 2-.9 2.69-.9l63.9 1.7c3.3.2 14.59.3 18 .3C452 288.1 480 275.93 480 256s-27.88-32-72.28-32z"
                fill="none" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" :stroke-width="strockeWidth" />
        </template>
        <template v-else-if="iconName == 'money'">
            <path
                d="M10 13.8333C11.8409 13.8333 13.3333 12.3409 13.3333 10.5C13.3333 8.65905 11.8409 7.16667 10 7.16667C8.15905 7.16667 6.66667 8.65905 6.66667 10.5C6.66667 12.3409 8.15905 13.8333 10 13.8333Z"
                :fill="stroke" />
            <path
                d="M15.8333 10.5C15.8333 10.9602 15.4602 11.3333 15 11.3333C14.5398 11.3333 14.1667 10.9602 14.1667 10.5C14.1667 10.0398 14.5398 9.66667 15 9.66667C15.4602 9.66667 15.8333 10.0398 15.8333 10.5Z"
                :fill="stroke" />
            <path
                d="M5 11.3333C5.46024 11.3333 5.83333 10.9602 5.83333 10.5C5.83333 10.0398 5.46024 9.66667 5 9.66667C4.53976 9.66667 4.16667 10.0398 4.16667 10.5C4.16667 10.9602 4.53976 11.3333 5 11.3333Z"
                :fill="stroke" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.28213 3.00936C2.2167 3.00317 2.15039 3 2.08333 3C0.93274 3 0 3.93274 0 5.08333C0 5.15039 0.00316779 5.2167 0.00936189 5.28213C0.0031636 5.35393 0 5.4266 0 5.5V15.5C0 15.5734 0.0031636 15.6461 0.00936189 15.7179C0.0031678 15.7833 0 15.8496 0 15.9167C0 17.0673 0.93274 18 2.08333 18C2.15039 18 2.2167 17.9968 2.28213 17.9906C2.35393 17.9968 2.4266 18 2.5 18H17.5C17.5734 18 17.6461 17.9968 17.7179 17.9906C17.7833 17.9968 17.8496 18 17.9167 18C19.0673 18 20 17.0673 20 15.9167C20 15.8496 19.9968 15.7833 19.9906 15.7179C19.9968 15.6461 20 15.5734 20 15.5V5.5C20 5.4266 19.9968 5.35393 19.9906 5.28213C19.9968 5.2167 20 5.15039 20 5.08333C20 3.93274 19.0673 3 17.9167 3C17.8496 3 17.7833 3.00317 17.7179 3.00936C17.6461 3.00316 17.5734 3 17.5 3H2.5C2.4266 3 2.35393 3.00316 2.28213 3.00936ZM4.12499 4.66667C4.15232 4.8013 4.16667 4.94064 4.16667 5.08333C4.16667 6.23393 3.23393 7.16667 2.08333 7.16667C1.94064 7.16667 1.8013 7.15232 1.66667 7.12499V13.875C1.8013 13.8477 1.94064 13.8333 2.08333 13.8333C3.23393 13.8333 4.16667 14.7661 4.16667 15.9167C4.16667 16.0594 4.15232 16.1987 4.12499 16.3333H15.875C15.8477 16.1987 15.8333 16.0594 15.8333 15.9167C15.8333 14.7661 16.7661 13.8333 17.9167 13.8333C18.0594 13.8333 18.1987 13.8477 18.3333 13.875V7.12499C18.1987 7.15232 18.0594 7.16667 17.9167 7.16667C16.7661 7.16667 15.8333 6.23393 15.8333 5.08333C15.8333 4.94064 15.8477 4.8013 15.875 4.66667H4.12499Z"
                :fill="stroke" />
        </template>
        <template v-else-if="iconName == 'server'">
            <ellipse cx="256" cy="128" rx="192" ry="80" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-miterlimit="10" stroke-width="32" />
            <path d="M448 214c0 44.18-86 80-192 80S64 258.18 64 214M448 300c0 44.18-86 80-192 80S64 344.18 64 300"
                fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" />
            <path d="M64 127.24v257.52C64 428.52 150 464 256 464s192-35.48 192-79.24V127.24" fill="none"
                stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" />
        </template>
        <template v-else-if="iconName == 'archive'">
            <path d="M80 152v256a40.12 40.12 0 0040 40h272a40.12 40.12 0 0040-40V152" fill="none" stroke="currentColor"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
            <rect x="48" y="64" width="416" height="80" rx="28" ry="28" fill="none" stroke="currentColor"
                stroke-linejoin="round" stroke-width="32" />
            <path fill="none" stroke="currentColor" stroke-line cap="round" stroke-linejoin="round" stroke-width="32"
                d="M320 304l-64 64-64-64M256 345.89V224" />
        </template>
        <template v-else-if="iconName == 'logout'">
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
            <polyline points="16 17 21 12 16 7" />
            <line x1="21" y1="12" x2="9" y2="12" />
        </template>
        <template v-else-if="iconName == 'stock'">
            <path
                d="M435.25 48h-122.9a14.46 14.46 0 00-10.2 4.2L56.45 297.9a28.85 28.85 0 000 40.7l117 117a28.85 28.85 0 0040.7 0L459.75 210a14.46 14.46 0 004.2-10.2v-123a28.66 28.66 0 00-28.7-28.8z"
                fill="none" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
            <path d="M384 160a32 32 0 1132-32 32 32 0 01-32 32z" />
        </template>
        <template v-else-if="iconName == 'handhand'">
            <g>
                <g>
                    <path
                        d="M510.303,288.954l-96-192c-3.968-7.904-13.536-11.072-21.472-7.168l-64,32c-3.84,1.92-6.72,5.28-8.064,9.344 c-1.344,4.064-0.96,8.512,1.024,12.32l4.48,8.672h-38.272c-4.256,0-8.32,1.696-11.328,4.672l-14.912,14.944l-29.664-17.408 c-2.432-1.44-5.248-2.208-8.096-2.208h-38.272l4.48-8.64c1.952-3.808,2.336-8.256,1.024-12.32 c-1.344-4.064-4.256-7.424-8.064-9.344l-64-32c-7.904-3.936-17.536-0.768-21.472,7.136l-96,192 c-3.904,7.776-0.832,17.248,6.848,21.312l60.672,32c2.304,1.216,4.864,1.856,7.456,1.856c1.6,0,3.232-0.256,4.768-0.736 c4.064-1.28,7.456-4.128,9.44-7.904l5.152-9.952l111.744,82.688c14.176,10.432,31.072,15.648,47.936,15.648 c17.12,0,34.24-5.376,48.416-16.192l110.432-84.8l6.528,12.608c1.984,3.776,5.344,6.624,9.44,7.904 c1.568,0.48,3.2,0.736,4.8,0.736c2.592,0,5.152-0.64,7.456-1.856l60.672-32C511.135,306.202,514.175,296.73,510.303,288.954z M69.887,306.458l-32.576-17.184l81.856-163.68l35.168,17.6l-9.568,18.464c-0.064,0.128-0.128,0.224-0.192,0.352L69.887,306.458z M284.735,382.266c-16.832,12.8-40.672,12.928-57.92,0.192L110.943,296.73l58.24-112.608h50.464l18.72,11.008l-57.696,57.664 c-5.824,5.856-6.272,15.2-0.992,21.536l12.512,15.072c14.528,17.472,39.456,22.432,59.072,11.968l34.944-18.24l62.752,49.824 L284.735,382.266z M375.103,312.858l-77.152-61.28c-4.96-3.904-11.744-4.544-17.344-1.632l-44.32,23.104 c-6.304,3.392-14.72,1.6-19.488-4.096l-3.168-3.84l80.992-80.992h48.192l56.832,109.888L375.103,312.858z M442.111,306.458 l-74.72-144.448c-0.064-0.128-0.128-0.224-0.192-0.352l-9.568-18.464l35.2-17.6l81.856,163.68L442.111,306.458z">
                    </path>
                </g>
            </g>
        </template>
        <template v-else-if="iconName == 'fbo'">
            <!-- Буква F -->
            <path d="M10,30 V70 H30 V50 H20 V40 H30 V30 Z" :fill="stroke" />
            <!-- Буква B -->
            <path d="M40,30 V70 H50 Q60,70 60,60 Q60,50 50,50 H40 M40,30 V40 Q50,40 50,50 V60 Q50,70 40,70 H40"
                :fill="stroke" />
            <!-- Буква O -->
            <circle cx="80" cy="50" r="20" :fill="stroke" />
        </template>
        <template v-else-if="iconName == 'extension'">
            <g clip-path="url(#clip0_2953_2496)">
                <path
                    d="M0.833313 3.33337V8.33337M0.833313 8.33337H5.83331M0.833313 8.33337L4.69998 4.70004C5.85074 3.55122 7.34369 2.80751 8.95388 2.58097C10.5641 2.35442 12.2043 2.65732 13.6273 3.44401C15.0504 4.2307 16.1793 5.45857 16.8438 6.94262C17.5084 8.42667 17.6726 10.0865 17.3118 11.672C16.951 13.2575 16.0847 14.6828 14.8434 15.7332C13.6021 16.7835 12.0531 17.402 10.4297 17.4954C8.80638 17.5889 7.19663 17.1522 5.84303 16.2512C4.48943 15.3502 3.46531 14.0337 2.92498 12.5"
                    :stroke="stroke" :stroke-width="strokeWidth" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 7.5V11.6667L12.5 12.9167" :stroke="stroke" :stroke-width="strokeWidth" stroke-linecap="round"
                    stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2953_2496">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'file-plus'">
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="12" y1="18" x2="12" y2="12"></line>
            <line x1="9" y1="15" x2="15" y2="15"></line>
        </template>
        <template v-else-if="iconName == 'key'">
            <path
                d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4">
            </path>
        </template>
        <template v-else-if="iconName == 'photo'">
            <g clip-path="url(#clip0_2491_26661)">
                <path
                    d="M22.9997 18.9998C22.9997 19.5302 22.789 20.0389 22.4139 20.414C22.0388 20.789 21.5301 20.9998 20.9997 20.9998H2.99997C2.46955 20.9998 1.96085 20.789 1.58578 20.414C1.21071 20.0389 1 19.5302 1 18.9998V7.99993C1 7.4695 1.21071 6.9608 1.58578 6.58574C1.96085 6.21067 2.46955 5.99996 2.99997 5.99996H6.99992L8.99989 3H14.9998L16.9998 5.99996H20.9997C21.5301 5.99996 22.0388 6.21067 22.4139 6.58574C22.789 6.9608 22.9997 7.4695 22.9997 7.99993V18.9998Z"
                    stroke="#1A1A1A" stroke-width="2.39997" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M11.9998 16.9998C14.209 16.9998 15.9998 15.209 15.9998 12.9999C15.9998 10.7908 14.209 8.99992 11.9998 8.99992C9.79074 8.99992 7.9999 10.7908 7.9999 12.9999C7.9999 15.209 9.79074 16.9998 11.9998 16.9998Z"
                    stroke="#1A1A1A" stroke-width="2.39997" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2491_26661">
                    <rect width="23.9997" height="23.9997" fill="white" />
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'edit'">
            <g clip-path="url(#clip0_2491_26675)">
                <path
                    d="M7.33301 2.66666H2.66634C2.31272 2.66666 1.97358 2.80713 1.72353 3.05718C1.47348 3.30723 1.33301 3.64637 1.33301 3.99999V13.3333C1.33301 13.6869 1.47348 14.0261 1.72353 14.2761C1.97358 14.5262 2.31272 14.6667 2.66634 14.6667H11.9997C12.3533 14.6667 12.6924 14.5262 12.9425 14.2761C13.1925 14.0261 13.333 13.6869 13.333 13.3333V8.66666M12.333 1.66666C12.5982 1.40144 12.9579 1.25244 13.333 1.25244C13.7081 1.25244 14.0678 1.40144 14.333 1.66666C14.5982 1.93187 14.7472 2.29158 14.7472 2.66665C14.7472 3.04173 14.5982 3.40144 14.333 3.66666L7.99967 9.99999L5.33301 10.6667L5.99967 7.99999L12.333 1.66666Z"
                    stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2491_26675">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'minus-circle'">
            <path
                d="M5.33301 7.99992H10.6663M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
        </template>
        <template v-else-if="iconName == 'plus-circle'">
            <g clip-path="url(#clip0_2491_26678)">
                <path
                    d="M7.99967 5.33325V10.6666M5.33301 7.99992H10.6663M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                    stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2491_26678">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'users'">
            <g clip-path="url(#clip0_3428_10497)">
                <path
                    d="M14.1667 17.5V15.8333C14.1667 14.9493 13.8155 14.1014 13.1904 13.4763C12.5652 12.8512 11.7174 12.5 10.8333 12.5H4.16668C3.28262 12.5 2.43478 12.8512 1.80965 13.4763C1.18453 14.1014 0.833344 14.9493 0.833344 15.8333V17.5M19.1667 17.5V15.8333C19.1661 15.0948 18.9203 14.3773 18.4678 13.7936C18.0153 13.2099 17.3818 12.793 16.6667 12.6083M13.3333 2.60833C14.0504 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0504 8.88308 13.3333 9.06667M10.8333 5.83333C10.8333 7.67428 9.34096 9.16667 7.50001 9.16667C5.65906 9.16667 4.16668 7.67428 4.16668 5.83333C4.16668 3.99238 5.65906 2.5 7.50001 2.5C9.34096 2.5 10.8333 3.99238 10.8333 5.83333Z"
                    :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3428_10497">
                    <rect width="20" height="20" :fill="stroke" />
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'list'">
            <line x1="8" y1="6" x2="21" y2="6"></line>
            <line x1="8" y1="12" x2="21" y2="12"></line>
            <line x1="8" y1="18" x2="21" y2="18"></line>
            <line x1="3" y1="6" x2="3.01" y2="6"></line>
            <line x1="3" y1="12" x2="3.01" y2="12"></line>
            <line x1="3" y1="18" x2="3.01" y2="18"></line>
        </template>
        <template v-else-if="iconName == 'box'">
            <path
                d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
            </path>
            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
            <line x1="12" y1="22.08" x2="12" y2="12"></line>
        </template>
        <template v-else-if="iconName == 'user'">
            <path
                d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round" stroke-linejoin="round" />
        </template>
        <template v-else-if="iconName == 'arrow-left'">
            <path d="M19 12H5M5 12L12 19M5 12L12 5" :stroke="stroke" :stroke-width="strockeWidth" stroke-linecap="round"
                stroke-linejoin="round" />
        </template>
        <template v-else-if="iconName == 'photo'">
            <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
            <circle cx="12" cy="13" r="4"></circle>
        </template>
        <template v-else-if="iconName == 'rubl'">
            <g clip-path="url(#clip0_3483_9519)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.3333 7.91658C13.3333 9.80873 11.8358 11.25 10 11.5901V12.4999H11.6667C12.1269 12.4999 12.5 12.873 12.5 13.3333C12.5 13.7935 12.1269 14.1666 11.6667 14.1666H10V14.9999C10 15.4602 9.62691 15.8333 9.16668 15.8333C8.70644 15.8333 8.33334 15.4602 8.33334 14.9999V14.1666H7.50001C7.03977 14.1666 6.66668 13.7935 6.66668 13.3333C6.66668 12.873 7.03977 12.4999 7.50001 12.4999H8.33334V4.99992C8.33334 4.53968 8.70644 4.16658 9.16668 4.16658C11.3599 4.16658 13.3333 5.74447 13.3333 7.91658ZM11.6667 7.91658C11.6667 8.75577 11.0202 9.58487 10 9.88352V5.94965C11.0202 6.2483 11.6667 7.0774 11.6667 7.91658Z"
                    fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 0.833252C4.9374 0.833252 0.833344 4.93731 0.833344 9.99992C0.833344 15.0625 4.9374 19.1666 10 19.1666C15.0626 19.1666 19.1667 15.0625 19.1667 9.99992C19.1667 4.93731 15.0626 0.833252 10 0.833252ZM2.50001 9.99992C2.50001 5.85778 5.85787 2.49992 10 2.49992C14.1421 2.49992 17.5 5.85778 17.5 9.99992C17.5 14.1421 14.1421 17.4999 10 17.4999C5.85787 17.4999 2.50001 14.1421 2.50001 9.99992Z"
                    fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_3483_9519">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </template>
        <template v-else-if="iconName == 'clock'">
            <g clip-path="url(#clip0_2491_26434)">
                <path
                    d="M7.99967 3.99992V7.99992L10.6663 9.33325M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                    :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2491_26434">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </template>
    </svg>
</template>
<script>
export default {
    props: {
        iconName: {
            type: String,
            default: "box",
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        stroke: {
            type: String,
            default: "currentColor",
        },
        viewBox: {
            type: String,
            default: "0 0 24 24",
        },
        fill: {
            type: String,
            default: "none",
        },
        strockeWidth: {
            type: [Number, String],
            default: 2,
        },
    },
    methods: {
        click() {
            this.$emit("click");
        }
    }
};
</script>
