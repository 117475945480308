<template>
    <div id="scanner-page">
        <Grid :column="1" :gap="20">
            <!-- :style="{ overflow: 'hidden' }" -->
            <Block>
                <Grid :column="1" :gap="5" :style="{ alignItems: 'center', padding: '5px' }">
                    <div>
                        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded" v-if="!wait">
                        </StreamBarcodeReader>
                        <b>{{ successMessage }}</b>
                        <!-- <div id="interactive" v-if="scanning && barcode == null"></div> -->
                        <!-- <qrcode-stream @detect="onDetect"></qrcode-stream> -->
                        <!-- <qrcode-stream @decode="onDecode"  v-if="!wait" ></qrcode-stream> -->
                    </div>
                    <div>
                        <Grid :column="1" :gap="5">
                            <!-- <v-btn type="submit" class="button mt-6" variant="tonal" color="primary" @click="startScanner()"
                                v-if="goodsData === null">
                                Сканировать
                            </v-btn> -->
                        </Grid>
                    </div>
                </Grid>
            </Block>
            <!-- <Block>
                <div id="interactive" style="width: 100%; height: 300px;"></div>
                <button @click="startScanner">Старт</button>
                <button @click="stopScanner">Стоп</button>
                <div>Результат: {{ scannedBarcode }}</div>
            </Block> -->
        </Grid>
    </div>
</template>
<script>
import { mapState } from 'vuex'
// import { actionTypes } from '@/store/modules/auth'
import { globalMethods } from '@/mixins/globalMethods'
// import 'es-module-shims';
// import VueQrcodeReader from "vue-qrcode-reader";
// import { StreamBarcodeReader } from "vue-barcode-reader";
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
    name: 'ScannerPage',
    components: {
        StreamBarcodeReader,
        //     VueQrcodeReader,
    },
    mixins: [globalMethods],
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        isMobyle() {
            return this.windowWidth <= 560
        },
        thisPage() {
            return this.currentpage + 1
        },
    },

    data() {
        return {
            scannedData: null,
            updFirst: false,
            scannedBarcode: null,
            scanning: false,
            barcode: null,
            place: null,
            wait: false,
            goodsData: null,
            successMessage: ''

        }
    },
    watch: {},
    mounted() {
        // this.getRegistration();
    },
    methods: {
        onDecode(article) {
            const self = this
            this.wait = true

            this.sendRequest('PUT', { article }, '/change_stock', {}, true, true)
                .then(() => {
                    self.wait = false
                    this.successMessage = `Оприходование для товара ${article} созданно`
                    setTimeout(() => {
                        this.successMessage = '';
                    }, 5000)
                })
                .catch(err => {
                    self.wait = false
                    this.$store.commit('setError', err)
                })
        },
        getRegistration() {
            this.sendRequest('GET', {}, '/get_registration', {}, true, true)
                .then(resolve => {
                    console.log(111111)
                    console.log(111111)
                    console.log(111111)
                    console.log(111111)
                    console.log(resolve)
                    // this.successMessage = `Оприходование для товара ${article} созданно`
                    // setTimeout(() => {
                    //     this.successMessage = '';
                    // }, 5000)
                })
                .catch(err => {
                    this.$store.commit('setError', err)
                })
        },
        onLoaded() {
            console.log(`Ready to start scanning barcodes`)
        }
    }
}
</script>
<style lang="scss">
#stocks-page {
    .table-scroll {
        height: 100vh;

        thead {
            position: sticky;
            top: 0;
            z-index: 3;
        }

        tbody {
            tr {
                &.red {
                    td {
                        background: rgba(159, 51, 51, 0.376);
                    }
                }

                &.green {
                    td {
                        background: rgba(98, 159, 51, 0.376);
                    }
                }

                &.blue {
                    td {
                        background: rgba(51, 114, 159, 0.376);
                    }
                }
            }
        }
    }

    .btn-w-border {
        max-width: 200px !important;
        overflow: hidden;
    }
}

#interactive {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    width: 300px;
    height: 300px;
    min-height: 300px;
    max-height: 300px;

    video {
        max-height: 300px !important;
        min-height: 300px !important;
        height: 300px !important;
        max-width: 300px !important;
        min-width: 300px !important;
        width: 300px !important;
        margin: auto;
    }

    canvas {
        display: none;
    }
}

.v-btn.purpur {
    background: #A13453 !important;
    color: #fff !important;
    width: 100%;
}

.v-btn.fiolet {
    background: #7034a1 !important;
    color: #fff !important;
    width: 100%;
    margin-bottom: 20px;
}

.row {
    margin-bottom: 7px;
    padding-bottom: 3px;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    gap: 10px;

    div {
        &:first-child {
            font-weight: bold;
        }

        &:last-child {
            text-align: right;
        }
    }

    ul {
        padding-left: 20px;
    }
}

#scanner-page {
    img {
        width: 60%;
        display: block;
        margin: auto;
    }
}
</style>