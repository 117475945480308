<!-- NotFound.vue -->

<template>
    <div>
        <h1>404 Not Found</h1>
        <p>Страница не найдена</p>
        <router-link to="/main">На главную</router-link>
    </div>
</template>
  
<script>
export default {
    name: "NotFound"
};
</script>
  